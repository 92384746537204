import { Parameters } from 'shared/src/workflow-types';

export function formatParameters(parameters: Parameters | undefined): string {
  if (parameters) {
    // @ts-ignore
    return parameters.parameters
      .filter(param => param.name != 'pvc-name')
      .map(param => `${param.value}`)
      .join(', ');
  }
  return '';
}
