import Modal from './modal';
import React from 'react';
import { convertToDate, formatDatetime } from 'shared/src/date-utils';
import { ModelProperty, TableHeader } from '../pages/edit-metadata-page';
import { Phase, Workflow, WorkflowNode } from 'shared/src/workflow-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as circleOutline } from '@fortawesome/free-regular-svg-icons';
import { StatusBadge } from '../pages/in-progress-section';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useInterval } from 'usehooks-ts';
import { formatParameters } from 'shared/src/workflow-utils';

type Props = {
  job: Workflow;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function JobProgressModal({ job, open, setOpen }: Props) {
  return (
    <Modal
      title={job.modelId}
      open={open}
      setOpen={setOpen}
      content={<Progress job={job} />}
      footer={null}
    />
  );
}

type MetadataProps = {
  job: Workflow;
};

function Progress({ job }: MetadataProps) {
  return (
    <div className="px-8 pb-4">
      <div className="flex gap-x-8">
        <ModelProperty label="Model" value={job.modelId} />
        <ModelProperty label="Job Id" value={job.name} />
      </div>
      <SubtaskProgress subtasks={job.subtasks} />
    </div>
  );
}

function SubtaskProgress({ subtasks }: { subtasks: WorkflowNode[] | undefined }) {
  // main template is the entire job, just want to show subtasks here
  const filtered = subtasks?.filter(subtask => subtask.templateName !== 'main') || [];
  return (
    <div className="mb-8 mr-8 mt-12">
      <div className="text-md">
        <div className="mt-2 grid grid-cols-[30px_1fr_1fr_1fr_1fr] gap-y-3">
          <TableHeader label="" />
          <TableHeader label="Step" />
          <TableHeader label="Start Time" />
          <TableHeader label="Duration" />
          <TableHeader label="Status" />
          {filtered.map((subtask, idx) => (
            <React.Fragment key={idx}>
              <Circle phase={subtask.phase} />
              <div>
                {subtask.displayName} {formatParameters(subtask.inputs)}
              </div>
              <div>{formatDatetime(subtask.startedAt)}</div>
              <JobDuration startString={subtask.startedAt} endString={subtask.finishedAt} />
              <div>
                <StatusBadge status={subtask.phase} />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

type JobDurationProps = {
  startString: string | null;
  endString: string | null;
};

function Circle({ phase }: { phase: Phase }) {
  return (
    <div className="pt-1 text-xs">
      <FontAwesomeIcon
        className="text-green-700"
        icon={phase === 'Succeeded' ? faCircle : circleOutline}
      />
    </div>
  );
}

function JobDuration({ startString, endString }: JobDurationProps) {
  const startDate = convertToDate(startString);
  const endDate = convertToDate(endString);
  if (startDate && endDate) {
    const duration = intervalToDuration({ start: startDate, end: endDate });
    return <div>{formatDuration(duration)}</div>;
  } else if (startDate) {
    return <LiveDuration startDate={startDate} />;
  } else {
    return <div>Unknown</div>;
  }
}

function LiveDuration({ startDate }: { startDate: Date }) {
  const [duration, setDuration] = React.useState(liveDuration(startDate));
  useInterval(() => {
    setDuration(liveDuration(startDate));
  }, 1000);
  return <div>{duration}</div>;
}

function liveDuration(startDate: Date) {
  const duration = intervalToDuration({ start: startDate, end: new Date() });
  return formatDuration(duration);
}
