import * as React from 'react';
import { ScrapedMetadata } from 'shared/src/metadata-types';
import { Navigate } from 'react-router-dom';

type Props = {
  scrapedMetadata: ScrapedMetadata | null;
};

export function Completed({ scrapedMetadata }: Props) {
  if (!scrapedMetadata) return <Navigate to="/create/new" />;
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="mb-4 flex items-center">
        <div className="text-2xl font-light">Submitted Model for Quantization</div>
      </div>
      <div className="mb-32 font-light italic text-gray-400">
        {scrapedMetadata.organization.name}/{scrapedMetadata.name} has been submitted
        <a href="/processing" className="ml-4 text-blue-600">
          View Progress
        </a>
      </div>
    </div>
  );
}
