import { format, isValid } from 'date-fns';

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  if (isValid(date)) return format(date, 'MMM do yyyy');
  console.warn('Invalid date ' + dateString + ' found');
  return '';
}

export function formatDatetime(dateString: string | null): string {
  if (!dateString) return '-';
  const date = new Date(dateString);
  if (isValid(date)) return format(date, 'MMM do yyyy - HH:mm:ss');
  console.warn('Invalid date ' + dateString + ' found');
  return '';
}

export function convertToDate(dateString: string | null): Date | null {
  if (!dateString) return null;
  const date = new Date(dateString);
  if (isValid(date)) return date;
  console.warn('Invalid date ' + dateString + ' found');
  return null;
}
