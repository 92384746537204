import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

type Props = {
  children: ReactNode;
};

export function ProtectedRoute({ children }: Props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingAuthStatus, setIsCheckingAuthStatus] = useState(true);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get('/auth/check', { withCredentials: true });
        setIsAuthenticated(response.data);
      } catch (error) {
        console.error('Error checking authentication status:', error);
        setIsAuthenticated(false);
      }
      setIsCheckingAuthStatus(false);
    };

    checkAuthStatus();
  }, []);

  if (isCheckingAuthStatus) {
    return (
      <div className="animate-pulse rounded-lg bg-white p-4 shadow-md">
        <div className="mb-2 h-4 w-2/3 rounded bg-gray-300"></div>
        <div className="mb-2 h-8 w-full rounded bg-gray-300"></div>
        <div className="mb-2 h-8 w-full rounded bg-gray-300"></div>
        <div className="h-8 w-1/2 rounded bg-gray-300"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
}
