import { ScrapedMetadata } from 'shared/src/metadata-types';
import * as React from 'react';
import { useState } from 'react';
import { PrimaryButton, TextInput } from '../components/forms';
import { trpc } from '../utils/trpc-client';
import { useNavigate } from 'react-router-dom';

type NewRequestProps = {
  setScrapedMetadata: (metadata: ScrapedMetadata) => void;
};

export function NewRequest({ setScrapedMetadata }: NewRequestProps) {
  const [modelId, setModelId] = useState('');
  const navigate = useNavigate();
  // TODO[mk] check the error state from useMutation
  const { mutateAsync, isLoading, error } = trpc.scrape.useMutation();

  async function handleSubmit() {
    if (!modelId || modelId.trim() === '') return;
    const scrapedMetadata = await mutateAsync({ id: modelId });
    setScrapedMetadata(scrapedMetadata);
    navigate('/create/edit-metadata');
  }

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="mb-4 flex items-center">
        <TextInput
          placeholder="e.g. mistralai/Mistral-7B-v0.1"
          value={modelId}
          onChange={setModelId}
        />
        <PrimaryButton
          label={isLoading ? 'Scraping Metadata...' : 'Start'}
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </div>
      <div className="mb-8 font-light italic text-gray-400">
        Enter a hugging face model id to get started
      </div>

      {error ? <ErrorPanel message={error.message} /> : <div className="h-32" />}
    </div>
  );
}

type ErrorPanelProps = {
  message?: string;
};

function ErrorPanel({ message }: ErrorPanelProps) {
  return (
    <div className="h-32">
      <div className="rounded-md border-[1px] bg-red-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Error scraping model info from Hugging Face
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc space-y-1 pl-5">
                <li>{message}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
