import * as React from 'react';
import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  content: ReactNode;
  footer: ReactNode | null;
};
export default function Modal({ open, setOpen, title, content, footer = null }: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-15"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-15">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-15 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative max-h-[80vh] w-4/5 transform rounded-lg bg-white pt-5 text-left shadow-xl transition-all">
                <div className="h-full w-full flex-col">
                  <div className="flex h-12 justify-between px-4">
                    <div />
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <FontAwesomeIcon
                      className="hover:cursor-pointer"
                      icon={faClose}
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <div className="max-h-[60vh] overflow-y-scroll">{content}</div>
                  {footer}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
