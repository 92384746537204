import * as React from 'react';

type TextInputProps = {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
};

export function TextInput({ placeholder, value, onChange }: TextInputProps) {
  return (
    <input
      autoFocus
      type="text"
      autoComplete="off"
      value={value}
      onChange={e => onChange(e.target.value)}
      className="mr-2 block h-[56px] rounded-xl border-0 px-8 py-4 font-light text-gray-900 shadow-sm ring-1  ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-green-500 sm:w-96 md:w-[500px]"
      placeholder={placeholder}
    />
  );
}

type PrimaryButtonProps = {
  label: string;
  onClick: () => unknown;
  disabled?: boolean;
};

export function PrimaryButton({ label, onClick, disabled = false }: PrimaryButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type="button"
      className="h-[54px] rounded-md bg-green-600 px-2 text-lg font-light text-white shadow-lg hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:bg-gray-300 sm:w-24 md:w-48">
      {label}
    </button>
  );
}

export function SecondaryButton({ label, onClick, disabled = false }: PrimaryButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type="button"
      className="h-[40px] rounded-md bg-green-600 px-2 py-2 font-light text-white shadow-lg hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:bg-gray-300 sm:w-24 md:w-48">
      {label}
    </button>
  );
}
