import React, { useEffect, useState } from 'react';
import { trpc } from '../utils/trpc-client';
import Modal from './modal';
import { SecondaryButton, TextInput } from './forms';

export type ModelGroupSelectorProps = {
  groupId?: number;
  onGroupChange: (groupId: number) => void;
};

export default function ModelGroupSelector({ groupId, onGroupChange }: ModelGroupSelectorProps) {
  const { data, isLoading, refetch } = trpc.groups.useQuery({});
  const { mutateAsync, isLoading: createPending } = trpc.addGroup.useMutation();
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groupName, setGroupName] = useState('');
  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    if (groupId !== undefined) {
      setSelectedGroup(`${groupId}`);
    } else if (data && data.length > 0) {
      // Set the default value as the first one after tags are loaded
      const defaultTagId = data[0].id;
      setSelectedGroup(`${defaultTagId}`);
      onGroupChange(defaultTagId);
    }
  }, [data, groupId, onGroupChange]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleSelectChange = (event: any) => {
    const groupId = event.target.value;
    if (groupId !== 'create_new') {
      setSelectedGroup(groupId);
      onGroupChange(parseInt(groupId));
    } else {
      setCreateOpen(true);
    }
  };

  async function createGroup() {
    const group = await mutateAsync({ name: groupName });
    setCreateOpen(false);
    await refetch();
    setGroupName('');
    setSelectedGroup(`${group.id}`);
    onGroupChange(group.id);
  }

  return (
    <div>
      <select
        id="model_group"
        name="Model Group"
        className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={selectedGroup}
        onChange={handleSelectChange}>
        {data?.map((group, idx) => (
          // eslint-disable-next-line react/jsx-key
          <option value={group.id} key={`model_group_opt_${idx}`}>
            {group.name}
          </option>
        ))}
        <option value="create_new">Create new...</option>
      </select>
      <Modal
        title={'Create New Model Group'}
        open={createOpen}
        setOpen={setCreateOpen}
        content={<CreateGroupProperty groupName={groupName} setGroupName={setGroupName} />}
        footer={
          <Footer
            enabled={!createPending}
            label={createPending ? 'Creating Group...' : 'Create Group'}
            submit={createGroup}
          />
        }
      />
    </div>
  );
}

type CreateGroupPropertyProps = {
  groupName: string;
  setGroupName: (name: string) => void;
};

export function CreateGroupProperty({ groupName, setGroupName }: CreateGroupPropertyProps) {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="mb-4 flex items-center">
        <TextInput placeholder="e.g. mistralai" value={groupName} onChange={setGroupName} />
      </div>
    </div>
  );
}

interface FooterProps {
  enabled: boolean;
  label: string;
  submit: () => unknown;
}

function Footer({ enabled, label, submit }: FooterProps) {
  return (
    <div className="flex h-20 items-center justify-end rounded-b-lg border-t-[1px] px-4">
      <SecondaryButton disabled={!enabled} label={label} onClick={submit} />
    </div>
  );
}
