import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';

const containerStyles = xcss({
  maxWidth: '700px',
  padding: 'space.0'
});

/*
  As inline edit allows for a custom input component, styling of `ReadViewContainer` needs to be shipped with the component.
  This keeps `editView` and `readView` components aligned when switching between the two. In this particular case, these
  styles ensure `readView` is in sync with the TextField.
  */
const readViewContainerStyles = xcss({
  display: 'flex',
  maxWidth: '600px',
  padding: `space.0`,
  fontSize: `16px`,
  lineHeight: `24px`,
  wordBreak: 'break-word'
});

type Props = {
  initialValue: string;
  override: string | null | undefined;
  setOverride: (value: string) => void;
};

export function EditableTextField({ initialValue, override, setOverride }: Props) {
  return (
    <Box xcss={containerStyles}>
      <InlineEdit
        defaultValue={initialValue}
        editView={({ errorMessage, ...fieldProps }) => <Textfield {...fieldProps} autoFocus />}
        readView={() => (
          <Box xcss={readViewContainerStyles} testId="read-view">
            {override || initialValue || 'Click to Edit'}
            {override && <div className="pl-1 mt-[-4px] text-red-300">*</div>}
          </Box>
        )}
        onConfirm={value => setOverride(value)}
      />

    </Box>
  );
}
