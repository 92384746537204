import * as React from 'react';
import { trpc } from '../utils/trpc-client';
import { Badge, Cell, Header, LoadingBadge, Table } from '../components/table';
import { convertToDate, formatDatetime } from 'shared/src/date-utils';
import { Phase, Workflow } from 'shared/src/workflow-types';
import { JobProgressModal } from '../components/job-progress-modal';
import { formatDuration, intervalToDuration } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleStop } from '@fortawesome/free-regular-svg-icons';
import ConfirmDialog from '../components/confirm-dialog';

export function InProgressSection() {
  const { data, isLoading, refetch } = trpc.inProgressJobs.useQuery(undefined, {
    refetchInterval: 5000
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <Header label="Model Id" />
          <Header label="Name" />
          <Header label="Started At" />
          <Header label="Finished At" />
          <Header label="Duration" />
          <Header label="Status" />
          <Header label="" />
          <Header label="" />
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {data?.map(job => (
          <tr key={job.name}>
            <Cell>{job.modelId}</Cell>
            <Cell>{job.name}</Cell>
            <Cell>{formatDatetime(job.startedAt)}</Cell>
            <Cell>{formatDatetime(job.finishedAt)}</Cell>
            <Cell>{durationString(job.startedAt, job.finishedAt)}</Cell>
            <Cell>
              <StatusBadge status={job.status} />
            </Cell>
            <Cell>
              <WorkflowModal job={job} />
            </Cell>
            <Cell>
              <StopButton workflow={job} refetch={refetch} />
            </Cell>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export function StatusBadge({ status }: { status: Phase }) {
  switch (status) {
    case 'Pending':
      return <Badge label={status} color="yellow" />;
    case 'Running':
      return <LoadingBadge label={status} color="blue" />;
    case 'Succeeded':
      return <Badge label={status} color="green" />;
    case 'Skipped':
      return <Badge label={status} color="yellow" />;
    case 'Failed':
      return <Badge label={status} color="red" />;
    case 'Error':
      return <Badge label={status} color="red" />;
    case 'Omitted':
      return <Badge label={status} color="indigo" />;
  }
}

type ModelModalTypes = {
  job: Workflow;
};

function WorkflowModal({ job }: ModelModalTypes) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="text-indigo-600 hover:cursor-pointer hover:text-indigo-900">
        View
      </div>
      <JobProgressModal job={job} open={open} setOpen={setOpen} />
    </>
  );
}

function durationString(start: string | null, end: string | null) {
  const startDate = convertToDate(start);
  const endDate = convertToDate(end);

  if (startDate && endDate) {
    return formatDuration(intervalToDuration({ start: startDate, end: endDate }));
  } else {
    return '-';
  }
}

type StopButtonProps = {
  workflow: Workflow;
  refetch: () => void;
};

function StopButton({ workflow, refetch }: StopButtonProps) {
  const [open, setOpen] = React.useState(false);
  const { mutateAsync } = trpc.terminateJob.useMutation();

  async function onConfirm() {
    await mutateAsync({ name: workflow.name });
    refetch();
    setOpen(false);
  }

  return (
    <>
      <div className="flex items-center">
        <FontAwesomeIcon
          className="text-lg text-gray-300 hover:cursor-pointer hover:text-red-500"
          title="Stop Workflow"
          icon={faCircleStop}
          onClick={() => setOpen(true)}
        />
      </div>
      <ConfirmDialog
        title="Terminate Job"
        open={open}
        setOpen={setOpen}
        onConfirm={onConfirm}
        message={
          <p className="text-sm text-gray-500">
            Are you sure you want to terminate the{' '}
            <span className="font-bold">{workflow.name}</span> job?
          </p>
        }
      />
    </>
  );
}
