import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

type Props = {
  children: React.ReactNode;
};

export function Table({ children }: Props) {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">{children}</table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Header({ label }: { label: string }) {
  return (
    <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-gray-900 ">
      {label}
    </th>
  );
}

export function Cell({ children }: { children: ReactNode }) {
  return (
    <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-700">{children}</td>
  );
}

type Color = 'red' | 'yellow' | 'green' | 'blue' | 'indigo' | 'purple' | 'pink';

export function Badge({ label, color }: { label: string; color: Color }) {
  return (
    <span
      className={`inline-flex items-center rounded-md px-2 py-1.5 text-xs font-medium ring-1 ring-inset ${colors(color)}`}>
      {label}
    </span>
  );
}

export function LoadingBadge({ label, color }: { label: string; color: Color }) {
  return (
    <span
      className={`inline-flex items-center rounded-md px-2 py-0 text-xs font-medium ring-1 ring-inset ${colors(color)}`}>
      {label}
      <motion.div
        className="ml-5 text-lg"
        animate={{ rotate: 360 }}
        transition={{
          ease: 'linear',
          duration: 2,
          repeat: Infinity
        }}>
        <FontAwesomeIcon icon={faMicrochip} className="w-8 text-blue-500" />
      </motion.div>
    </span>
  );
}

function colors(color: Color) {
  switch (color) {
    case 'red':
      return 'bg-red-50 text-red-700 ring-red-700/10';
    case 'yellow':
      return 'bg-yellow-50 text-yellow-700 ring-yellow-700/10';
    case 'green':
      return 'bg-green-50 text-green-700 ring-green-700/10';
    case 'blue':
      return 'bg-blue-50 text-blue-700 ring-blue-700/10';
    case 'indigo':
      return 'bg-indigo-50 text-indigo-700 ring-indigo-700/10';
    case 'purple':
      return 'bg-purple-50 text-purple-700 ring-purple-700/10';
    case 'pink':
      return 'bg-pink-50 text-pink-700 ring-pink-700/10';
  }
}
