import * as React from 'react';
import { trpc } from '../utils/trpc-client';
import { Cell, Header, Table } from '../components/table';

import { QuantizedFile } from 'shared/src/metadata-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpLong } from '@fortawesome/free-solid-svg-icons';
import { RefetchOptions } from '@tanstack/react-query';
import ConfirmDialog from '../components/confirm-dialog';

export function UnpublishedSection() {
  const { data, isLoading, refetch } = trpc.unpublishedModels.useQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <Header label="Model Id" />
          <Header label="Quant Method" />
          <Header label="Quant Engine" />
          <Header label="Evaluations" />
          <Header label="Publish" />
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {
          // @ts-ignore
          [...data]
            .sort((a, b) => a.id.localeCompare(b.id))
            .map(model => (
              <tr key={model.id}>
                <Cell>{model.id}</Cell>
                <Cell>{model.quantMethod}</Cell>
                <Cell>{model.quantEngine}</Cell>
                <Cell>
                  <div style={{ whiteSpace: 'pre-line' }} className="flex gap-1">
                    {model.evaluations
                      // @ts-ignore
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((e: { name: string; value: string }) => e.name + ': ' + e.value + '\n')}
                  </div>
                </Cell>
                <Cell>
                  <PublishButton model={model} refetch={refetch} />
                </Cell>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
}

type PublishButtonProps = {
  model: QuantizedFile;
  refetch: (options?: RefetchOptions) => unknown;
};

function PublishButton({ model, refetch }: PublishButtonProps) {
  const [open, setOpen] = React.useState(false);
  const { mutateAsync } = trpc.publishModelQuant.useMutation();

  async function onConfirm() {
    await mutateAsync({
      id: model.id,
      quantMethod: model.quantMethod
    });
    refetch();
    setOpen(false);
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faArrowUpLong}
        className="text-gray-400 hover:cursor-pointer hover:text-red-600"
        onClick={() => setOpen(true)}
      />
      <ConfirmDialog
        title="Publish Model"
        open={open}
        setOpen={setOpen}
        onConfirm={onConfirm}
        message={
          <p className="text-sm text-gray-500">
            Are you sure you want to publish the <span className="font-bold">{model.id}</span>{' '}
            model? Any consumers of the Kurator API will be able to download this model
          </p>
        }
      />
    </>
  );
}
