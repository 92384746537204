import * as React from 'react';
import { useState } from 'react';
import { ScrapedMetadata } from 'shared/src/metadata-types';
import { EditMetadataPage } from './edit-metadata-page';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NewRequest } from './new-model-request';
import { Completed } from './completed';

export function CreateSection() {
  const [scrapedMetadata, setScrapedMetadata] = useState<ScrapedMetadata | null>(null);

  return (
    <Routes>
      <Route path="new" element={<NewRequest setScrapedMetadata={setScrapedMetadata} />} />
      <Route path="edit-metadata" element={<EditMetadataPage scrapedMetadata={scrapedMetadata} />} />
      <Route path="completed" element={<Completed scrapedMetadata={scrapedMetadata}/>} />
      <Route path="*" element={<Navigate to="new" />} />
    </Routes>
  );
}
