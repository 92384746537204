import * as React from 'react';
import { useCallback, useState } from 'react';
import { ScrapedMetadata } from 'shared/src/metadata-types';
import { formatDate } from 'shared/src/date-utils';
import { formatBytes } from '../utils/formatting-utils';
import { EditableTextField } from '../components/editable-text-field';
import { PrimaryButton } from '../components/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { trpc } from '../utils/trpc-client';
import ModelGroupSelector from '../components/model-group-selector';

export type EditableFields = Partial<
  Pick<
    ScrapedMetadata,
    | 'description'
    | 'infoUrl'
    | 'paperUrl'
    | 'licenseDetails'
    | 'groupId'
    | 'trainedFor'
    | 'name'
    | 'baseModel'
  >
>;

type Props = { scrapedMetadata: ScrapedMetadata | null };

export function EditMetadataPage({ scrapedMetadata }: Props) {
  if (!scrapedMetadata) return <Navigate to="/create/new" />;
  return Metadata({ scrapedMetadata });
}

type MetadataProps = {
  scrapedMetadata: ScrapedMetadata;
};

function Metadata({ scrapedMetadata }: { scrapedMetadata: ScrapedMetadata }) {
  const [modelOverrides, setModelOverrides] = useState<EditableFields>({});
  const { mutateAsync } = trpc.submitJob.useMutation();
  const navigate = useNavigate();

  async function setOverride(key: keyof EditableFields, value: any) {
    setModelOverrides(prev => ({ ...prev, [key]: value }));
  }

  const setGroupId = useCallback(
    (groupId: number) => {
      setModelOverrides(prev => ({ ...prev, groupId }));
    },
    [setModelOverrides]
  );

  async function submitJob() {
    await mutateAsync({ ...scrapedMetadata, ...modelOverrides });
    navigate('/create/completed');
  }

  return (
    <div className="flex w-full flex-col rounded-lg px-32 py-8">
      <EditableModelProperty
        label="Name"
        value={scrapedMetadata.name}
        setOverride={value => setOverride('name', value)}
        override={modelOverrides.name}
      />
      <ModelProperty label="Publisher" value={scrapedMetadata.organization.name} />
      <EditableModelProperty
        label="Description"
        value={scrapedMetadata.description}
        setOverride={value => setOverride('description', value)}
        override={modelOverrides.description}
      />
      <EditableModelProperty
        label="Trained For"
        value={scrapedMetadata.trainedFor}
        setOverride={value => setOverride('trainedFor', value)}
        override={modelOverrides.trainedFor}
      />
      <div className="flex gap-x-8">
        <ModelProperty label="Date Published" value={formatDate(scrapedMetadata.firstPublished)} />
        <ModelProperty
          label="Date Updated"
          value={formatDate(scrapedMetadata.rawFileSet.publishedDate)}
        />
      </div>
      <div className="flex gap-x-8">
        <EditableModelProperty
          label="Info Url"
          value={scrapedMetadata.infoUrl}
          setOverride={value => setOverride('infoUrl', value)}
          override={modelOverrides.infoUrl}
        />
        <EditableModelProperty
          label="Paper Url"
          value={scrapedMetadata.paperUrl}
          setOverride={value => setOverride('paperUrl', value)}
          override={modelOverrides.paperUrl}
        />
      </div>
      <div className="flex gap-x-8">
        <ModelProperty label="Model Type" value={scrapedMetadata.modelType} />
        <ModelProperty label="Library Name" value={scrapedMetadata.libraryName} />
      </div>
      <EditableModelProperty
        label="Base Model"
        value={scrapedMetadata.baseModel || ''}
        setOverride={value => setOverride('baseModel', value)}
        override={modelOverrides.baseModel}
      />
      <ModelProperty label="License" value={scrapedMetadata.license} />
      <ModelProperty
        label="License Link"
        value={scrapedMetadata.licenseDetails.licenseLink || ''}
      />
      <ModelProperty label="License Body" value={scrapedMetadata.licenseDetails.body || ''} />
      <ModelProperty label="Terms Of Use" value={scrapedMetadata.licenseDetails.termsOfUse || ''} />
      <ModelProperty label="Copyright" value={scrapedMetadata.licenseDetails.copyright || ''} />
      <ModelProperty
        label="Languages"
        value={scrapedMetadata.languages.length > 0 ? scrapedMetadata.languages.join(',') : 'NA'}
      />
      <ModelGroupProperty
        label="Model Group"
        groupId={modelOverrides.groupId}
        setGroupId={setGroupId}
      />
      <ModelFiles scrapedMetadata={scrapedMetadata} />
      <div className="mt-16">
        <PrimaryButton label="Submit Job" onClick={submitJob} />
      </div>
    </div>
  );
}

function ModelFiles({ scrapedMetadata }: MetadataProps) {
  return (
    <div className="mr-8 mt-8">
      <div className="text-sm italic text-gray-500">Files</div>
      <div className="text-md">
        <div className="mt-2 grid grid-cols-[auto_150px_1fr] gap-x-16 gap-y-1">
          <TableHeader label="Filename" />
          <TableHeader label="Sha" />
          <TableHeader label="File Size" />
          {scrapedMetadata.rawFileSet.files.map((file, idx) => (
            <React.Fragment key={`${idx}-${file.sha256}`}>
              <div>{file.name}</div>
              <div className="truncate">{file.sha256}</div>
              <div>{file.sizeBytes <= 0 ? '-' : formatBytes(file.sizeBytes)}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export function TableHeader({ label }: { label: string }) {
  return (
    <div className="text-sm font-medium italic text-gray-500 underline underline-offset-2">
      {label}
    </div>
  );
}

export function ModelProperty({ label, value }: { label: string; value: string }) {
  return (
    <div className="mr-8 mt-6">
      <div className="text-sm italic text-gray-500">{label}</div>
      <div className="text-md">{value}</div>
    </div>
  );
}

type ModelGroupPropertyProps = {
  label: string;
  groupId?: number;
  setGroupId: (groupId: number) => void;
};

export function ModelGroupProperty({ label, groupId, setGroupId }: ModelGroupPropertyProps) {
  return (
    <div className="mr-8 mt-6">
      <div className="text-sm italic text-gray-500">{label}</div>
      <ModelGroupSelector groupId={groupId} onGroupChange={setGroupId} />
    </div>
  );
}

export type EditableModelProps = {
  label: string;
  value: string;
  override: string | null | undefined;
  setOverride: (value: string) => void;
};

export function EditableModelProperty({ label, value, override, setOverride }: EditableModelProps) {
  return (
    <div className="mr-8 mt-8">
      <div className="flex items-center">
        <div className="text-sm italic text-gray-500">{label}</div>
        <FontAwesomeIcon icon={faPencil} className="mb-1 ml-2 text-xs text-gray-500" />
      </div>
      <div className="ml-[-3px] mt-[-8px]">
        <EditableTextField initialValue={value} override={override} setOverride={setOverride} />
      </div>
    </div>
  );
}
