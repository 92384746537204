import { Link, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import * as React from 'react';
import icon from '../anaconda-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

export function Header() {
  return (
    <div className="flex  items-center border-b px-4">
      <img src={icon} alt="Anaconda Icon" />
      <div className="flex-1" />
      <Menu />
      <div className="flex-1" />
      <a href="/auth/logout">
        <div className="text-gray-500 hover:cursor-pointer hover:text-gray-800">
          <FontAwesomeIcon className="text-xl" icon={faRightFromBracket} />
        </div>
      </a>
    </div>
  );
}

function Menu() {
  return (
    <div className="flex h-full">
      <MenuItem basePath="/create" path="/create/new" label="Create" />
      <MenuItem basePath="/processing" path="/processing" label="In Progress" />
      <MenuItem basePath="/published" path="/published" label="Published" />
      <MenuItem basePath="/unpublished" path="/unpublished" label="Unpublished" />
    </div>
  );
}

function MenuItem({ path, label, basePath }: { path: string; label: string; basePath: string }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(basePath);
  return (
    <div
      className={clsx(
        'mx-8 flex h-full items-center border-b-4 px-2 pt-2 font-light',
        isActive ? 'border-green-500' : 'border-white'
      )}>
      <Link to={path}>{label}</Link>
    </div>
  );
}
