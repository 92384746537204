import * as React from 'react';
import { useState } from 'react';
import './index.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CreateSection } from './pages/create-section';
import { InProgressSection } from './pages/in-progress-section';
import { PublishedSection } from './pages/published-section';
import { Header } from './components/header';
import { ProtectedRoute } from './auth/protected-route';
import { Login } from './pages/login';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { trpc } from './utils/trpc-client';
import { UnpublishedSection } from './pages/unpublished-section';

export function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() => {
    return trpc.createClient({
      links: [
        httpBatchLink({
          url: '/trpc'
        })
      ]
    });
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ProtectedRoute>
            <div className="grid h-screen grid-cols-1 grid-rows-[60px,1fr]">
              <Header />
              <div className="h-full bg-gray-100">
                <Routes>
                  <Route path="/create/*" element={<CreateSection />} />
                  <Route path="/processing" element={<InProgressSection />} />
                  <Route path="/published" element={<PublishedSection />} />
                  <Route path="/unpublished" element={<UnpublishedSection />} />
                  <Route path="*" element={<Navigate to="/create/new" />} />
                </Routes>
              </div>
            </div>
          </ProtectedRoute>
          <Routes>
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
